<template>
	<div>
		<b-row class="form">
			<b-col sm="2" class="text-left mb-4">
				<b-form-group :label="FormMSG(210, 'Spent to date')" label-for="initial">
					<b-form-input :disabled="true" :value="displayCurrency(budgetData.spent)" type="text" id="initial" size="sm" />
				</b-form-group>
			</b-col>
			<b-col sm="2" class="text-left mb-4">
				<b-form-group :label="FormMSG(211, 'Estimated to complete')" label-for="initial">
					<b-form-input
						:disabled="true"
						:value="displayCurrency(budgetData.amountLeft)"
						type="text"
						id="initial"
						size="sm"
						:class="{ 'text-color-negative': budgetData.amountLeft < 0 }"
					/>
				</b-form-group>
			</b-col>
			<b-col sm="2" class="text-left mb-4">
				<b-form-group :label="FormMSG(212, 'Estimated final cost')" label-for="initial">
					<b-form-input
						:disabled="true"
						:value="displayCurrency(budgetData.initial)"
						type="text"
						id="initial"
						size="sm"
						:class="{ 'text-color-negative ': budgetData.initial < 0 }"
					/>
				</b-form-group>
			</b-col>
			<b-col sm="2" class="text-left mb-4">
				<b-form-group :label="FormMSG(213, 'Locked initial budget')" label-for="initial">
					<b-form-input :disabled="true" :value="displayCurrency(budgetData.budgetDetailInitialBudget)" type="text" id="initial" size="sm" />
				</b-form-group>
			</b-col>
			<b-col sm="2" class="text-left mb-4">
				<b-form-group :label="FormMSG(214, 'Variance')" label-for="initial">
					<b-form-input
						:disabled="true"
						:value="displayCurrency(budgetData.totalVariance)"
						type="text"
						id="initial"
						size="sm"
						:class="{ 'text-color-negative': budgetData.totalVariance > 0 }"
					/>
				</b-form-group>
			</b-col>
			<b-col sm="2" class="text-left mb-4">
				<b-form-group :label="FormMSG(215, 'CO2')" label-for="initial">
					<b-form-input :disabled="true" :value="displayKgCo2(budgetData.kgCoTwoTotal)" type="text" id="initial" size="sm" />
				</b-form-group>
			</b-col>
		</b-row>
		<div
			class="container-toggle-total-budget"
			v-b-toggle="idCollapse"
			:style="isToggle ? `border-radius: 5px 5px 0 0 !important` : ''"
			@click="handleClickContainerToggle"
		>
			<b-row>
				<b-col cols="10">
					{{ labelToggle }}
				</b-col>
				<b-col cols="2">
					<div class="float-right">
						<chevron-down v-if="!isToggle" :size="16" />
						<chevron-up v-if="isToggle" :size="16" />
					</div>
				</b-col>
			</b-row>
		</div>
		<b-collapse :id="idCollapse">
			<b-card>
				<b-row class="form">
					<b-col sm="2">
						<h5>{{ this.FormMSG(27, 'Expenses') }}</h5>
					</b-col>
					<b-col sm="2" class="text-left mb-4">
						<b-form-group :label="FormMSG(10, 'Initial:')" label-for="expInitial" :label-cols="0">
							<b-form-input :disabled="true" :value="displayCurrency(budgetData.initialExpenses)" type="text" id="expInitial" size="sm" />
						</b-form-group>
					</b-col>
					<b-col sm="2" class="text-left mb-4">
						<b-form-group :label="FormMSG(13, 'Expenses:')" label-for="expenseTotal" :label-cols="0">
							<b-form-input :disabled="true" :value="displayCurrency(budgetData.expenseTotal)" type="text" id="expenseTotal" size="sm" />
						</b-form-group>
					</b-col>
					<b-col sm="3" class="text-left mb-4">
						<b-form-group :label="FormMSG(15, 'Purchase orders:')" label-for="po" :label-cols="0">
							<b-form-input :disabled="true" :value="displayCurrency(budgetData.purchaseOrdersTotal)" type="text" id="po" size="sm" />
						</b-form-group>
					</b-col>
					<b-col sm="3" class="text-left mb-4">
						<b-form-group label="CO2" label-for="kge" :label-cols="0">
							<b-form-input :disabled="true" :value="displayKgCo2(budgetData.kgCoTwoExpenses)" type="text" id="kge" size="sm" />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row class="form">
					<b-col sm="2">
						<h5>{{ this.FormMSG(28, 'Salaries') }}</h5>
					</b-col>
					<b-col sm="2" class="text-left mb-4">
						<b-form-group :label="FormMSG(10, 'Initial:')" label-for="expSalaries" :label-cols="0">
							<b-form-input :disabled="true" :value="displayCurrency(budgetData.initialSalaries)" type="text" id="expSalaries" size="sm" />
						</b-form-group>
					</b-col>
					<b-col sm="2" class="text-left mb-4">
						<b-form-group :label="FormMSG(14, 'Salaries:')" label-for="salaries" :label-cols="0">
							<b-form-input :disabled="true" :value="displayCurrency(budgetData.salaries)" type="text" id="salaries" size="sm" />
						</b-form-group>
					</b-col>
					<b-col sm="3" class="text-left mb-4">
						<b-form-group :label="FormMSG(19, 'Contracts:')" label-for="contracted" :label-cols="0">
							<b-form-input :disabled="true" :value="displayCurrency(budgetData.contracted)" type="text" id="contracted" size="sm" />
						</b-form-group>
					</b-col>
					<b-col sm="3" class="text-left mb-4">
						<b-form-group label="CO2" label-for="kgs" :label-cols="0">
							<b-form-input :disabled="true" :value="displayKgCo2(budgetData.kgCoTwoSalaries)" type="text" id="kgs" size="sm" />
						</b-form-group>
					</b-col>
				</b-row>
			</b-card>
		</b-collapse>
	</div>
</template>

<script>
import { rendCurrency, rendKgCo2 } from '~helpers';
import { ChevronDown, ChevronUp } from 'lucide-vue';
import languageMessages from '@/mixins/languageMessages';
import { store } from '@/store';

export default {
	name: 'TotalGeneralBudget',

	mixins: [languageMessages],

	components: {
		ChevronDown,
		ChevronUp
	},

	props: {
		budgetTotal: { type: Object, required: true },
		idCollapse: { type: String, required: true }
	},

	data() {
		return {
			budgetData: {
				initial: 0,
				spent: 0,
				amountLeft: 0,
				kgCoTwoTotal: 0,
				initialExpenses: 0,
				expenseTotal: 0,
				purchaseOrdersTotal: 0,
				kgCoTwoExpenses: 0,
				initialSalaries: 0,
				salaries: 0,
				contracted: 0,
				kgCoTwoSalaries: 0,
				totalVariance: 0,
				budgetDetailInitialBudget: 0
			},
			isToggle: false
		};
	},

	computed: {
		styleObject() {
			var myObj = {
				color: 'black',
				fontWeight: '700'
			};
			if (this.budgetData.amountLeft >= 0) {
				myObj.color = 'green';
			} else {
				myObj.color = 'red';
			}
			return myObj;
		},

		labelToggle() {
			if (!this.isToggle) {
				return `${this.FormMSG(53, 'Expenses')}: ${this.displayCurrency(this.budgetData.expenseTotal)} - ${this.FormMSG(
					54,
					'Salaries'
				)}: ${this.displayCurrency(this.budgetData.salaries)}`;
			} else {
				return this.FormMSG(52, 'Expenses and Salaries');
			}
		}
	},

	watch: {
		budgetTotal: {
			handler(val) {
				this.budgetData.initial = val.initial;
				this.budgetData.spent = val.spent;
				this.budgetData.amountLeft = val.amountLeft;
				this.budgetData.kgCoTwoTotal = val.kgCoTwoTotal;
				this.budgetData.initialExpenses = val.initialExpenses;
				this.budgetData.expenseTotal = val.expenseTotal;
				this.budgetData.purchaseOrdersTotal = val.purchaseOrdersTotal;
				this.budgetData.kgCoTwoExpenses = val.kgCoTwoExpenses;
				this.budgetData.initialSalaries = val.initialSalaries;
				this.budgetData.salaries = val.salaries;
				this.budgetData.contracted = val.contracted;
				this.budgetData.kgCoTwoSalaries = val.kgCoTwoSalaries;
				this.budgetData.totalVariance = val.totalVariance;
				this.budgetData.budgetDetailInitialBudget = val.budgetDetailInitialBudget;
			},
			immediate: true,
			deep: true
		}
	},

	methods: {
		handleClickContainerToggle() {
			this.isToggle = !this.isToggle;
		},
		displayCurrency(value) {
			return rendCurrency(value);
		},
		displayKgCo2(value) {
			return rendKgCo2(value);
		}
	}
};
</script>
