var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "form" },
        [
          _c(
            "b-col",
            { staticClass: "text-left mb-4", attrs: { sm: "2" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(210, "Spent to date"),
                    "label-for": "initial",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      disabled: true,
                      value: _vm.displayCurrency(_vm.budgetData.spent),
                      type: "text",
                      id: "initial",
                      size: "sm",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-left mb-4", attrs: { sm: "2" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(211, "Estimated to complete"),
                    "label-for": "initial",
                  },
                },
                [
                  _c("b-form-input", {
                    class: {
                      "text-color-negative": _vm.budgetData.amountLeft < 0,
                    },
                    attrs: {
                      disabled: true,
                      value: _vm.displayCurrency(_vm.budgetData.amountLeft),
                      type: "text",
                      id: "initial",
                      size: "sm",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-left mb-4", attrs: { sm: "2" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(212, "Estimated final cost"),
                    "label-for": "initial",
                  },
                },
                [
                  _c("b-form-input", {
                    class: {
                      "text-color-negative ": _vm.budgetData.initial < 0,
                    },
                    attrs: {
                      disabled: true,
                      value: _vm.displayCurrency(_vm.budgetData.initial),
                      type: "text",
                      id: "initial",
                      size: "sm",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-left mb-4", attrs: { sm: "2" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(213, "Locked initial budget"),
                    "label-for": "initial",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      disabled: true,
                      value: _vm.displayCurrency(
                        _vm.budgetData.budgetDetailInitialBudget
                      ),
                      type: "text",
                      id: "initial",
                      size: "sm",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-left mb-4", attrs: { sm: "2" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(214, "Variance"),
                    "label-for": "initial",
                  },
                },
                [
                  _c("b-form-input", {
                    class: {
                      "text-color-negative": _vm.budgetData.totalVariance > 0,
                    },
                    attrs: {
                      disabled: true,
                      value: _vm.displayCurrency(_vm.budgetData.totalVariance),
                      type: "text",
                      id: "initial",
                      size: "sm",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-left mb-4", attrs: { sm: "2" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(215, "CO2"),
                    "label-for": "initial",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      disabled: true,
                      value: _vm.displayKgCo2(_vm.budgetData.kgCoTwoTotal),
                      type: "text",
                      id: "initial",
                      size: "sm",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: _vm.idCollapse,
              expression: "idCollapse",
            },
          ],
          staticClass: "container-toggle-total-budget",
          style: _vm.isToggle ? `border-radius: 5px 5px 0 0 !important` : "",
          on: { click: _vm.handleClickContainerToggle },
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "10" } }, [
                _vm._v("\n\t\t\t\t" + _vm._s(_vm.labelToggle) + "\n\t\t\t"),
              ]),
              _c("b-col", { attrs: { cols: "2" } }, [
                _c(
                  "div",
                  { staticClass: "float-right" },
                  [
                    !_vm.isToggle
                      ? _c("chevron-down", { attrs: { size: 16 } })
                      : _vm._e(),
                    _vm.isToggle
                      ? _c("chevron-up", { attrs: { size: 16 } })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-collapse",
        { attrs: { id: _vm.idCollapse } },
        [
          _c(
            "b-card",
            [
              _c(
                "b-row",
                { staticClass: "form" },
                [
                  _c("b-col", { attrs: { sm: "2" } }, [
                    _c("h5", [_vm._v(_vm._s(this.FormMSG(27, "Expenses")))]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "text-left mb-4", attrs: { sm: "2" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(10, "Initial:"),
                            "label-for": "expInitial",
                            "label-cols": 0,
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              disabled: true,
                              value: _vm.displayCurrency(
                                _vm.budgetData.initialExpenses
                              ),
                              type: "text",
                              id: "expInitial",
                              size: "sm",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "text-left mb-4", attrs: { sm: "2" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(13, "Expenses:"),
                            "label-for": "expenseTotal",
                            "label-cols": 0,
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              disabled: true,
                              value: _vm.displayCurrency(
                                _vm.budgetData.expenseTotal
                              ),
                              type: "text",
                              id: "expenseTotal",
                              size: "sm",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "text-left mb-4", attrs: { sm: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(15, "Purchase orders:"),
                            "label-for": "po",
                            "label-cols": 0,
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              disabled: true,
                              value: _vm.displayCurrency(
                                _vm.budgetData.purchaseOrdersTotal
                              ),
                              type: "text",
                              id: "po",
                              size: "sm",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "text-left mb-4", attrs: { sm: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "CO2",
                            "label-for": "kge",
                            "label-cols": 0,
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              disabled: true,
                              value: _vm.displayKgCo2(
                                _vm.budgetData.kgCoTwoExpenses
                              ),
                              type: "text",
                              id: "kge",
                              size: "sm",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "form" },
                [
                  _c("b-col", { attrs: { sm: "2" } }, [
                    _c("h5", [_vm._v(_vm._s(this.FormMSG(28, "Salaries")))]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "text-left mb-4", attrs: { sm: "2" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(10, "Initial:"),
                            "label-for": "expSalaries",
                            "label-cols": 0,
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              disabled: true,
                              value: _vm.displayCurrency(
                                _vm.budgetData.initialSalaries
                              ),
                              type: "text",
                              id: "expSalaries",
                              size: "sm",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "text-left mb-4", attrs: { sm: "2" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(14, "Salaries:"),
                            "label-for": "salaries",
                            "label-cols": 0,
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              disabled: true,
                              value: _vm.displayCurrency(
                                _vm.budgetData.salaries
                              ),
                              type: "text",
                              id: "salaries",
                              size: "sm",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "text-left mb-4", attrs: { sm: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(19, "Contracts:"),
                            "label-for": "contracted",
                            "label-cols": 0,
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              disabled: true,
                              value: _vm.displayCurrency(
                                _vm.budgetData.contracted
                              ),
                              type: "text",
                              id: "contracted",
                              size: "sm",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "text-left mb-4", attrs: { sm: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "CO2",
                            "label-for": "kgs",
                            "label-cols": 0,
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              disabled: true,
                              value: _vm.displayKgCo2(
                                _vm.budgetData.kgCoTwoSalaries
                              ),
                              type: "text",
                              id: "kgs",
                              size: "sm",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }