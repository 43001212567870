<style>
.searched {
	background-color: #00a09d57;
}
</style>

<template>
	<div>
		<b-row style="border: 1px solid #dfdfdf !important; margin: 0">
			<b-col :cols="manageColsField(5, 4, 3)">
				<span style="display: inline-block" :style="{ marginLeft: nodeMargin + 'px' }">
					<SvgPlus v-if="budget.budgetDetailHasChild && !showChildren" @clicked="toggleChildren(budget)" />
					<SvgMinus v-if="budget.budgetDetailHasChild && showChildren" @clicked="toggleChildren(budget)" />
				</span>
				{{ budget.name }}
			</b-col>
			<b-col cols="1" class="text-right" :class="{ 'text-link': !budget.budgetDetailHasChild }" @click="handleClickPOTotal(budget)">
				{{ displayCurrency(budget.purchaseOrdersTotal) }}
			</b-col>
			<b-col cols="1" class="text-right">
				{{ displayCurrency(budget.budgetDetailStartOfPeriodValue) }}
			</b-col>
			<b-col cols="1" class="text-right" :class="{ 'text-link': !budget.budgetDetailHasChild }" @click="handleClickTotalSpent(budget)">
				{{ displayCurrency(budget.totalSpent) }}
			</b-col>
			<b-col v-show="showColumnCo2" cols="1" class="text-right">
				{{ displayKgCo2(budget.kgCoTwo) }}
			</b-col>
			<b-col
				cols="1"
				class="text-right"
				:class="{
					'text-color-negative': budget.amountLeft < 0
				}"
			>
				{{ displayCurrency(budget.amountLeft) }}
			</b-col>
			<b-col cols="1" class="text-right">
				{{ displayCurrency(budget.budgetDetailInitialBudget) }}
			</b-col>
			<b-col cols="1" class="text-right bg-positive">
				{{ displayCurrency(budget.budgetDetailInitial) }}
			</b-col>
			<b-col
				cols="1"
				class="text-right"
				:class="{
					'text-color-negative': budget.budgetDetailVariance > 0
				}"
			>
				{{ displayCurrency(budget.budgetDetailVariance) }}
			</b-col>
			<b-col v-show="!hideActionColumn" cols="1" class="text-center">
				<b-dropdown
					v-if="canAddOrEditCode || canModifyEFC"
					id="dropdown-budget-action"
					no-caret
					dropleft
					boundary="window"
					class="btn-transparent border-none"
					variant="none"
					:disabled="budget.category === 0"
					size="sm"
				>
					<template #button-content>
						<MoreVertical
							v-if="
								(!budget.budgetDetailHasChild && canModifyEFC) ||
								(budget.department === 0 && canAddOrEditCode) ||
								(budget.budgetDetailParentId > 0 && canAddOrEditCode) ||
								canAddOrEditCode
							"
							:size="16"
						/>
					</template>
					<b-dropdown-item v-if="!budget.budgetDetailHasChild && canModifyEFC" @click.stop="handleClickModifyEFC(budget)">
						<span class="text-color-rhapsody-in-blue"> <LineChart :size="16" /> &nbsp; {{ FormMSG(234, 'Modify EFC') }} </span>
					</b-dropdown-item>
					<b-dropdown-item
						v-if="
							(budget.department === 0 && canAddOrEditCode && !budget.budgetDetailHasChild) ||
							(budget.budgetDetailParentId > 0 && canAddOrEditCode && !budget.budgetDetailHasChild)
						"
						@click.stop="handleClickEditBudget(budget)"
					>
						<span class="text-color-rhapsody-in-blue"> <Edit :size="16" /> &nbsp; {{ FormMSG(235, 'Edit') }} </span>
					</b-dropdown-item>
					<b-dropdown-item v-if="canAddOrEditCode" @click.stop="handleClickCreateSubCategory(budget)">
						<span class="text-color-rhapsody-in-blue"> <PlusCircle :size="16" /> &nbsp; {{ FormMSG(236, 'Create sub-category') }} </span>
					</b-dropdown-item>
				</b-dropdown>
				<b-dropdown v-else id="dropdown-budget-action" no-caret dropleft boundary="window" class="btn-transparent border-none" variant="none" size="sm">
					<template #button-content> <MoreVertical :size="16" /> </template>
					<b-dropdown-item>
						{{ FormMSG(240, 'No permitted action') }}
					</b-dropdown-item>
				</b-dropdown>
			</b-col>
		</b-row>
		<div v-if="showChildren">
			<div v-if="budget.budgetDetailHasChild">
				<tree-node-budget
					v-for="(child, index) in budget.children"
					:id="child.budgetDetailHasChild ? `t_${parentNodeStr},${child.budgetDetailId}` : `none-child-${child.budgetDetailId}`"
					:key="child.budgetDetailId + '_' + index"
					:budget="child"
					:department-id="departmentId"
					:spacing="spacing + 30"
					:start-date="startDate"
					:end-date="endDate"
					:show-column-co2="showColumnCo2"
					:hide-action="hideActionColumn"
					:parent-node-str="child.budgetDetailHasChild ? `${parentNodeStr},${child.budgetDetailId}` : parentNodeStr"
					@tree-node-budget:modify-EFC="handleTreeNodeBudgetModifyEFC"
					@tree-node-budget:edit="handleTreeNodeBudgetEdit"
					@tree-node-budget:add-sub-category="handleTreeNodeBudgetAddSubCategory"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import SvgPlus from '@/components/Offers/SvgPlus';
import SvgMinus from '@/components/Offers/SvgMinus';
import { rendCurrency, rendKgCo2 } from '~helpers';
import { store } from '@/store';
import { MoreVertical, Edit, LineChart, PlusCircle } from 'lucide-vue';
import GlobalMixin from '@/mixins/global.mixin';

export default {
	name: 'TreeNodeBudget',

	mixins: [GlobalMixin],

	components: {
		SvgPlus,
		SvgMinus,
		MoreVertical,
		Edit,
		LineChart,
		PlusCircle
	},

	props: {
		budget: { type: Object, required: true },
		spacing: { type: Number, default: 0 },
		departmentId: { type: Number, default: null },
		startDate: { type: String, required: false },
		endDate: { type: String, required: false },
		showColumnCo2: { type: Boolean, required: true },
		hideAction: { type: Boolean, default: false },
		parentNodeStr: { type: String, default: '' }
	},

	data() {
		return {
			showChildren: false,
			hideActionColumn: false
		};
	},

	computed: {
		canAddOrEditCode() {
			return store.canEditAndAddCode();
		},
		canModifyEFC() {
			return store.canModifyEstimatedFinalCost();
		},
		nodeMargin() {
			return this.spacing;
		},
		hasChildren() {
			return this.budget.budgetDetailHasChild;
		}
	},
	watch: {
		budget: {
			handler(val) {
				this.showChildren = val.is_selected;
			},
			immediate: true,
			deep: true
		},
		hideAction: {
			handler(newVal) {
				this.hideActionColumn = newVal;
			},
			immediate: true,
			deep: true
		}
	},
	created() {
		if (this.$route.query.search && this.$route.query.search !== '') {
			const newValSplited = this.$route.query.search.split(',');
			newValSplited.forEach((nVS) => {
				if (this.budget.budgetDetailId === parseInt(nVS)) {
					// console.log('SEARCH', nVS);
					this.showChildren = true;
				}
			});
		}
	},
	methods: {
		handleClickCreateSubCategory(payload) {
			this.$emit('tree-node-budget:add-sub-category', payload);
		},
		handleTreeNodeBudgetAddSubCategory(payload) {
			this.$emit('tree-node-budget:add-sub-category', payload);
		},
		handleClickEditBudget(payload) {
			this.$emit('tree-node-budget:edit', payload);
		},
		handleTreeNodeBudgetEdit(payload) {
			this.$emit('tree-node-budget:edit', payload);
		},
		handleClickModifyEFC(payload) {
			this.$emit('tree-node-budget:modify-EFC', payload);
		},
		handleTreeNodeBudgetModifyEFC(payload) {
			this.$emit('tree-node-budget:modify-EFC', payload);
		},
		manageColsField(valCond1, valCond2, valCond3) {
			setTimeout(() => {
				if (!this.showColumnCo2 && this.hideActionColumn) {
					return valCond1;
				}
				if (!this.showColumnCo2 && !this.hideActionColumn) {
					return valCond2;
				}
				if (this.showColumnCo2 && this.hideActionColumn) {
					return valCond3;
				}
			}, 250);
		},
		async toggleChildren() {
			this.showChildren = !this.showChildren;
		},
		handleClickTotalSpent(item) {
			if (!item.budgetDetailHasChild) {
				const budget = this.prepareDateExpense(item);

				if (item.contentType === 0) {
					store.setCurBudgetDataForDepartmentCategory(budget);

					const budgetDepCatDetailLink = this.budgetPerCategoryDetailLink(item);
					this.$router.push({
						path: budgetDepCatDetailLink
					});
				} else if (item.contentType === 1) {
					store.setCurBudgetDataForDepartmentCategory(budget);

					const budgetDepSalaryDetailLink = this.budgetDepSalaryDetailLink(item);
					this.$router.push({
						path: `${budgetDepSalaryDetailLink}`
					});
				}
			}
		},
		handleClickPOTotal(item) {
			if (!item.budgetDetailHasChild) {
				const budget = this.prepareDateExpense(item);

				store.setCurBudgetDataForDepartmentCategory(budget);

				this.$router.push({
					path: `/budget/purchase-orders/${item.department}/${item.category}?search=${this.parentNodeStr}`
				});
			}
		},
		prepareDateExpense(item) {
			const budget = {
				departmentName: item.departmentName,
				department: item.department,
				costCenter: item.cost_center,
				amountTotal: item.amountTotal,
				expenseTotal: item.expenseTotal,
				salaries: item.salaries,
				contracted: item.contracted,
				kgCoTwo: item.kgCoTwo,
				purchaseOrdersTotal: item.purchaseOrdersTotal,
				amountLeft: item.amountLeft,
				initialSalaries: item.initialSalaries,
				initialExpenses: item.initialExpenses,
				category: item.category,
				name: item.name,
				budgetDetail: {
					initial: item.budgetDetailInitial,
					contentType: item.contentType,
					description: item.budgetDetailDescription
				}
			};

			return budget;
		},
		rowClicked(item) {
			if (!item.budgetDetailHasChild) {
				store.setCurBudgetDataForDepartmentCategory(budget);
				if (item.category != 10 && item.contentType != 1) {
					const budgetDepCatDetailLink = this.budgetPerCategoryDetailLink(item);
					this.$router.push({
						path: budgetDepCatDetailLink
					});
				} else {
					const budgetDepSalaryDetailLink = this.budgetDepSalaryDetailLink(item);
					//                  console.log("link:",budgetDepSalaryDetailLink);
					this.$router.push({
						path: budgetDepSalaryDetailLink
					});
				}
			}
		},
		budgetPerCategoryDetailLink(item) {
			let url = `/budget/department/${item.department.toString()}/category/${item.category.toString()}?budgetPerCategory=1&search=${this.parentNodeStr}`;

			return url;
		},
		budgetDepSalaryDetailLink(item) {
			let url = `/budget/department/${item.department.toString()}/salariesForCategory/${item.category.toString()}?budgetPerCategory=1&search=${
				this.parentNodeStr
			}`;

			return url;
		},
		displayCurrency(value) {
			return rendCurrency(value);
		},
		displayKgCo2(value) {
			return rendKgCo2(value);
		}
	}
};
</script>
