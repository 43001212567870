var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        {
          staticStyle: { border: "1px solid #dfdfdf !important", margin: "0" },
        },
        [
          _c("b-col", { attrs: { cols: _vm.manageColsField(5, 4, 3) } }, [
            _c(
              "span",
              {
                staticStyle: { display: "inline-block" },
                style: { marginLeft: _vm.nodeMargin + "px" },
              },
              [
                _vm.budget.budgetDetailHasChild && !_vm.showChildren
                  ? _c("SvgPlus", {
                      on: {
                        clicked: function ($event) {
                          return _vm.toggleChildren(_vm.budget)
                        },
                      },
                    })
                  : _vm._e(),
                _vm.budget.budgetDetailHasChild && _vm.showChildren
                  ? _c("SvgMinus", {
                      on: {
                        clicked: function ($event) {
                          return _vm.toggleChildren(_vm.budget)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v("\n\t\t\t" + _vm._s(_vm.budget.name) + "\n\t\t"),
          ]),
          _c(
            "b-col",
            {
              staticClass: "text-right",
              class: { "text-link": !_vm.budget.budgetDetailHasChild },
              attrs: { cols: "1" },
              on: {
                click: function ($event) {
                  return _vm.handleClickPOTotal(_vm.budget)
                },
              },
            },
            [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(_vm.displayCurrency(_vm.budget.purchaseOrdersTotal)) +
                  "\n\t\t"
              ),
            ]
          ),
          _c("b-col", { staticClass: "text-right", attrs: { cols: "1" } }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(
                  _vm.displayCurrency(_vm.budget.budgetDetailStartOfPeriodValue)
                ) +
                "\n\t\t"
            ),
          ]),
          _c(
            "b-col",
            {
              staticClass: "text-right",
              class: { "text-link": !_vm.budget.budgetDetailHasChild },
              attrs: { cols: "1" },
              on: {
                click: function ($event) {
                  return _vm.handleClickTotalSpent(_vm.budget)
                },
              },
            },
            [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(_vm.displayCurrency(_vm.budget.totalSpent)) +
                  "\n\t\t"
              ),
            ]
          ),
          _c(
            "b-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showColumnCo2,
                  expression: "showColumnCo2",
                },
              ],
              staticClass: "text-right",
              attrs: { cols: "1" },
            },
            [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(_vm.displayKgCo2(_vm.budget.kgCoTwo)) +
                  "\n\t\t"
              ),
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "text-right",
              class: {
                "text-color-negative": _vm.budget.amountLeft < 0,
              },
              attrs: { cols: "1" },
            },
            [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(_vm.displayCurrency(_vm.budget.amountLeft)) +
                  "\n\t\t"
              ),
            ]
          ),
          _c("b-col", { staticClass: "text-right", attrs: { cols: "1" } }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(
                  _vm.displayCurrency(_vm.budget.budgetDetailInitialBudget)
                ) +
                "\n\t\t"
            ),
          ]),
          _c(
            "b-col",
            { staticClass: "text-right bg-positive", attrs: { cols: "1" } },
            [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(_vm.displayCurrency(_vm.budget.budgetDetailInitial)) +
                  "\n\t\t"
              ),
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "text-right",
              class: {
                "text-color-negative": _vm.budget.budgetDetailVariance > 0,
              },
              attrs: { cols: "1" },
            },
            [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(_vm.displayCurrency(_vm.budget.budgetDetailVariance)) +
                  "\n\t\t"
              ),
            ]
          ),
          _c(
            "b-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.hideActionColumn,
                  expression: "!hideActionColumn",
                },
              ],
              staticClass: "text-center",
              attrs: { cols: "1" },
            },
            [
              _vm.canAddOrEditCode || _vm.canModifyEFC
                ? _c(
                    "b-dropdown",
                    {
                      staticClass: "btn-transparent border-none",
                      attrs: {
                        id: "dropdown-budget-action",
                        "no-caret": "",
                        dropleft: "",
                        boundary: "window",
                        variant: "none",
                        disabled: _vm.budget.category === 0,
                        size: "sm",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [
                                (!_vm.budget.budgetDetailHasChild &&
                                  _vm.canModifyEFC) ||
                                (_vm.budget.department === 0 &&
                                  _vm.canAddOrEditCode) ||
                                (_vm.budget.budgetDetailParentId > 0 &&
                                  _vm.canAddOrEditCode) ||
                                _vm.canAddOrEditCode
                                  ? _c("MoreVertical", { attrs: { size: 16 } })
                                  : _vm._e(),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        347463990
                      ),
                    },
                    [
                      !_vm.budget.budgetDetailHasChild && _vm.canModifyEFC
                        ? _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleClickModifyEFC(_vm.budget)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "text-color-rhapsody-in-blue" },
                                [
                                  _c("LineChart", { attrs: { size: 16 } }),
                                  _vm._v(
                                    "   " +
                                      _vm._s(_vm.FormMSG(234, "Modify EFC")) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      (_vm.budget.department === 0 &&
                        _vm.canAddOrEditCode &&
                        !_vm.budget.budgetDetailHasChild) ||
                      (_vm.budget.budgetDetailParentId > 0 &&
                        _vm.canAddOrEditCode &&
                        !_vm.budget.budgetDetailHasChild)
                        ? _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleClickEditBudget(_vm.budget)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "text-color-rhapsody-in-blue" },
                                [
                                  _c("Edit", { attrs: { size: 16 } }),
                                  _vm._v(
                                    "   " +
                                      _vm._s(_vm.FormMSG(235, "Edit")) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.canAddOrEditCode
                        ? _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleClickCreateSubCategory(
                                    _vm.budget
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "text-color-rhapsody-in-blue" },
                                [
                                  _c("PlusCircle", { attrs: { size: 16 } }),
                                  _vm._v(
                                    "   " +
                                      _vm._s(
                                        _vm.FormMSG(236, "Create sub-category")
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _c(
                    "b-dropdown",
                    {
                      staticClass: "btn-transparent border-none",
                      attrs: {
                        id: "dropdown-budget-action",
                        "no-caret": "",
                        dropleft: "",
                        boundary: "window",
                        variant: "none",
                        size: "sm",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "button-content",
                          fn: function () {
                            return [_c("MoreVertical", { attrs: { size: 16 } })]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("b-dropdown-item", [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(240, "No permitted action")) +
                            "\n\t\t\t\t"
                        ),
                      ]),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showChildren
        ? _c("div", [
            _vm.budget.budgetDetailHasChild
              ? _c(
                  "div",
                  _vm._l(_vm.budget.children, function (child, index) {
                    return _c("tree-node-budget", {
                      key: child.budgetDetailId + "_" + index,
                      attrs: {
                        id: child.budgetDetailHasChild
                          ? `t_${_vm.parentNodeStr},${child.budgetDetailId}`
                          : `none-child-${child.budgetDetailId}`,
                        budget: child,
                        "department-id": _vm.departmentId,
                        spacing: _vm.spacing + 30,
                        "start-date": _vm.startDate,
                        "end-date": _vm.endDate,
                        "show-column-co2": _vm.showColumnCo2,
                        "hide-action": _vm.hideActionColumn,
                        "parent-node-str": child.budgetDetailHasChild
                          ? `${_vm.parentNodeStr},${child.budgetDetailId}`
                          : _vm.parentNodeStr,
                      },
                      on: {
                        "tree-node-budget:modify-EFC":
                          _vm.handleTreeNodeBudgetModifyEFC,
                        "tree-node-budget:edit": _vm.handleTreeNodeBudgetEdit,
                        "tree-node-budget:add-sub-category":
                          _vm.handleTreeNodeBudgetAddSubCategory,
                      },
                    })
                  }),
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }